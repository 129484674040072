import React, {Component} from "react"
import styles from "./Footer.module.css"
import imgLogo from "../Header/parts/img/phagans-logo-2.png"

const campuses = [
    {
        id: 1,
        region_id: 1,
        name: "SALEM",
        code: "PHAGANS’ SCHOOL OF BEAUTY",
        address: "622 Lancaster Drive, NE",
        state: "Salem, OR 97301",
        phone: "503-363-6800",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses1 = [
    {
        id: 1,
        region_id: 1,
        name: "MEDFORD",
        code: "PHAGANS’ MEDFORD BEAUTY SCHOOL",
        address: "2320 Poplar Drive",
        state: "Medford, OR 97504",
        phone: "541-772-6155",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses2 = [
    {
        id: 1,
        region_id: 1,
        name: "CORVALLIS",
        code: "PHAGANS’ BEAUTY COLLEGE",
        address: "1565 SW 53rd Street",
        state: "Corvallis, OR 97333",
        phone: "541-753-6466",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses3 = [
    {
        id: 1,
        region_id: 1,
        name: "BEND",
        code: "PHAGANS’ CENTRAL OREGON BEAUTY COLLEGE",
        address: "1310 NE Cushing Drive",
        state: "Bend, OR 97701",
        phone: "541-382-6171",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]
const campuses4 = [
    {
        id: 1,
        region_id: 1,
        name: "GRANTS PASS",
        code: "PHAGANS’ GRANTS PASS COLLEGE OF BEAUTY",
        address: "304 NE Agness Avenue, Suite F",
        state: "Grants Pass, OR 97526",
        phone: "541-479-6678",
        school_code: "",
        region: {
            id: 1,
            state: "Oregon",
            region: "Oregon"
        }
    }
]

const social = [
    {
        id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/PhagansBeauty/",
        icon: "fa fa-facebook"
    },
    {
        id: 2,
        name: "Instagram",
        link: "https://www.instagram.com/",
        icon: "fa fa-instagram"
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <div>
                        <div>
                            <img className={styles.logo} src={imgLogo} alt="phagans-logo" />
                        </div>
                        <div className={styles.social}>
                            {social.map(({id, link, icon}) => (
                                <a
                                    href={link}
                                    className={icon}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {""}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses3.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses1.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses2.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.campuses}>
                        <h5>
                            <strong>{""}</strong>
                        </h5>
                        {campuses4.map(({name, code, address, phone, state, id}) => (
                            <div className={styles.campusContainer} key={id}>
                                <div className={styles.name}>
                                    <strong>{name}</strong>
                                </div>
                                <div className={styles.code}>
                                    <strong>{code}</strong>
                                </div>
                                <div className={styles.address}>{address}</div>
                                <div className={styles.address}>{state}</div>
                                <div className={styles.phone}>
                                    <strong>Ph:</strong> {phone}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </footer>
        )
    }
}
